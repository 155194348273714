import { addLocaleData } from "react-intl";
import pt from "react-intl/locale-data/pt";

const messages = {
  // GLOBALS
  /*
    Before adding new keys here, add a key mapping in the keys.json file in this directory for
    intl and abstraction purposes.
   */
  error_prepay_remote:
    "Ocorreu um erro ao processar o seu pedido. Por favor contacte-nos diretamente.",
  post_questionnaire_delay: "Tempo de espera após Questionário (em horas)",
  backoffice_values: "Valores Editáveis",
  max_observation_size:
    "O tamanho máximo da observação é de {{max}} caracteres",
  observations_saved: "Observações guardadas!",
  values_saved: "Valores guardados!",
  block_report: "Impedir envio do Relatório",
  unblock_report: "Enviar do Relatório",
  add_observations: "Adicionar Observações",
  report_sent: "Relatório enviado",
  sent: "Enviado",
  not_sent: "Não enviado",
  go_back: "Voltar",
  observations: "observações / recomendações",
  all_rights_reserved: "Todos os direitos reservados",
  amplitude: "Amplitude",
  next: "Avançar",
  loading: "A carregar",
  search: "Procurar",
  reset: "Repor",
  required: "Obrigatório",
  start: "Início",
  prescriber: "Prescritor",
  no_prescriber: "sem prescritor",
  notes: "Notas",
  general_definitions: "Definições Gerais",
  help: "Ajuda",
  privacy_policy: "Politica de Privacidade",
  copyright: "copyright © 2019 Sensor",
  date: "Data",
  cancel: "Cancelar",
  save: "Guardar",
  other: "Outro",
  create: "Criar",
  upload: "Carregar",
  edit: "Editar",
  are_you_sure: "Tem a certeza?",
  yes: "Sim",
  no: "Não",
  price: "Preço",
  language: "Idioma",
  created_at: "Criado em",
  updated_at: "Atualizado em",
  read_at: "Lido em",
  title: "Título",
  users: "Utilizadores",
  message: "Mensagem",
  cta_questionnaire: "Responder ao Questionário",
  submit: "Submeter",
  view: "Visualizar",
  delete: "Apagar",
  ACTIVE: "Ativo",
  DISABLED: "Desativado",
  created_by: "Criado por",
  actions: "Acções",
  answers: "Respostas",
  completed: "Completo",
  incompleted: "Incompleto",
  questionnaire: "Questionário",
  questions: "Perguntas",
  open: "Aberta",
  multiple: "Multipla",
  text: "Texto",
  closed: "Fechada",
  close_window: "Pode fechar esta janela agora.",
  single: "Única",
  usersHeader: "Utilizadores",
  history: "Histórico",
  answerQuestionnaire: "Responder Questionário",
  status: "Estado",
  items: "Itens",
  user: "Utilizador",
  up: "Para cima",
  down: "Para baixo",
  ok: "Sim",
  criteria: "Critérios",
  criteriaUsage: "Uso de Critérios",
  metaSkill: "Meta Habilidades",
  principle: "Princípios",
  code: "Código",
  name: "Nome",
  question: "Pergunta",
  answer: "Resposta",
  all: "Todos",
  meta_competences: "Meta Competências",
  confirm: "Confirmar",
  return: "Voltar",
  invites: "Convites",
  invite: "Convidar",
  send: "Enviar",
  elements: "Elementos",
  attributes: "Atributos",
  energy_flow: "Fluxo de Energia",
  sending: "A Enviar",
  feelings: "Sentimentos",
  organs: "Órgãos",
  meta_data: "Meta Dados",
  metadata_bank: "Banco de Meta Dados",
  output_metadata: "Meta Dados do Output",
  input_metadata: "Meta Dados do Input",
  outputs: "Outputs",
  inputs: "Inputs",
  image_metadata: "Meta Dados da Imagem",
  image: "Imagem",
  output_information: "Informação do Output",
  none: "Nenhum",
  add_input: "Adicionar Input",
  add_output: "Adicionar Output",
  input_name: "Nome dos Inputs",
  input_content: "Conteúdo do Input",
  type: "Tipo",
  output_content: "Conteúdo do Output",
  output_name: "Nome do Output",
  change_type: "Alterar tipo",
  input_information: "Informação do Input",
  dowload_excel_users: "Exportar para Excel",
  excel_users_title: "Lista_Utilizadores",
  printPDF: "Imprimir em PDF",
  element_attributes: "Atributos do Elemento",
  attributes_bank: "Banco de Atributos",
  sense: "Sentidos",
  duplicate: "Duplicar",
  natural_elements: "Elementos Naturais",
  add_natural_element: "Criar Novo Elemento Natural",
  create_new_natural_element: "Criar Novo Elemento Natural",
  edit_natural_element: "Editar Elemento Natural",
  add_season: "Criar Nova Estação",
  create_new_season: "Criar Nova Estação",
  edit_season: "Editar Estação",
  season: "Estação",
  add_character: "Criar Novo Carácter",
  create_new_character: "Criar Novo Carácter",
  edit_character: "Editar Carácter",
  character: "Caracteres",
  basic_settings: "Definições Básicas",
  security_settings: "Definições de Segurança",
  first_name: "Nome",
  last_name: "Apelido",
  birth_place: "Local de Nascimento",
  job_name: "Profissão",
  nationality: "Nacionalidade",
  gender: "Género",
  birth_date: "Data de Nascimento",
  old_password: "Password",
  new_password: "Escolha uma nova Palavra-passe",
  choose_password: "Escolha uma Palavra-passe",
  password: "Password",
  confirm_email: "Confirmar Email",
  confirm_password: "Confirmar Palavra-passe",
  change: "Alterar",
  profile: "Perfil de Utilizador",
  male: "Masculino",
  female: "Feminino",
  change_password: "Alterar Palavra-passe",
  invalid_password: "Palavra-passe Inválida",
  password_changed: "A sua palavra-passe foi alterada",
  required_password: "Por favor, introduza a palavra-passe",
  match_password: "Palavras-passe diferentes",
  password_recovery: "Recuperação de palavra-passe",
  password_recovery_msg:
    "Foi enviado um email com um link para alterar a sua palavra-passe. Verifique a sua caixa de entrada.",
  match_email: "Emails diferentes",
  input_email: "Por favor, introduza o seu email",
  invalid_email: "Email inválido",
  profile_updated: "Perfil atualizado",
  profile_updated_message: "O seu perfil foi atualizado",
  creation_date: "Data do Registo",
  no_information: "Sem Informação",
  manage_question_criteria: "Gerir Critérios da Pergunta",
  logout: "Sair",
  login: "Fazer login",
  login_success: "Login efetuado com sucesso!",
  register: "Registar",
  register_success: "Registo efetuado com sucesso!",
  auth_questionnaire_msg:
    "Irá ser redireccionado para o Questionário. \nReceberá no seu e-mail um link, caso queira voltar mais tarde. \nClique OK para continuar.",
  new_notification: "Nova Notificação",
  send_notification_to: "Enviar Notificação Para",
  all_users: "Todos os Utilizadores",
  some_users: "Alguns Utilizadores",
  dynamic_keys: "Chaves dinâmicas",
  confirm_message_disable: "Gostaria de desactivar",
  confirm_message_enable: "Gostaria de activar",
  confirm_this_male: "este",
  confirm_this_female: "esta",
  enable: "activar",
  disable: "desactivar",
  answer_option: "Opção de resposta",
  text_answer_placeholder: "Escreva aqui a resposta",
  one_criteria: "critério",
  one_criteria_usage: "utilidade de critérios",
  one_meta_skill: "competência",
  one_principle: "princípio",
  resending_invitation: "Reenviar Convite",
  resending_invitation_to: "Reenviar convite para",
  to_fill_questionnaire: "para preencher o questionário",
  clipboard: "Copiar Código do Convite",
  save_changes: "Guardar as Alterações",
  discard_changes: "Descartar as Alterações",
  mobile_answer_component: "Componentes",
  one_mobile_answer_component: "Componente de Resposta Mobile",
  add_mobile_answer_component: "Criar Novo Componente de Resposta Mobile",
  create_new_mobile_answer_component:
    "Criar Novo Componente de Resposta Mobile",
  edit_mobile_answer_component: "Editar Componente de Resposta Mobile",
  disable_mobile_answer_component: "Desactivar Componente de Resposta Mobile",
  enable_mobile_answer_component: "Activar Componente de Resposta Mobile",
  general: "Geral",
  algorithm_values: "Valores do Algoritmo",
  skill_anchor: "Habilidades de Alavancagem",
  skill_compensated: "Habilidade de Compensação",
  skill_decompensated: "Habilidade de Descompensação",
  criteria_management: "Gestão de Critérios",
  free_in_app: "Grátis na APP",
  add_criteria: "Adicionar Novo Critério",
  edit_criteria: "Editar Critério",
  free: "Grátis",
  initial_date: "Data de Inicio",
  expire_date: "Data de Fim",
  description: "Descrição",
  entities_assigned: "Entidades Atribuídas",
  entities: "Entidades",
  effect_type: "Tipo de Efeito",
  effect: "Efeitos",
  create_new_highlight_output: "Criar Novo Highlight Output",
  highlight_type: "Tipos de Destaque",
  create_new_highlight_type: "Criar Novo Tipo de Destaque",
  link_android: "Hiperligação Android",
  link_ios: "Hiperligação IOS",
  mobile_apps: "Aplicações Móveis",
  text_config: "Configuração de Texto",
  assign_entities: "Atribuir Entidades",
  disable_text_config: "Desactivar configuração de texto",
  enable_text_config: "Activar configuração de texto",
  env: "Variáveis de Ambiente",
  add_env: "Criar Nova Variável de Ambiente",
  daily_challenge: "Desafio Diário",
  number_of_inputs: "Número de Inputs",
  min_days_of_interval: "Mínimo dias de Intervalo",
  add_highlight_type: "Adicionar Tipo de Destaque",
  daily_challenge_saved: "Desafio Diário guardado",
  create_new_highlight_questionnaire: "Criar Novo Highlight - Questionário",
  create_new_highlight_question: "Criar Novo Highlight - Pergunta",
  edit_highlight_questionnaire: "Editar Highlight - Questionário",
  edit_highlight_question: "Editar Highlight - Pergunta",
  type_image: "IMAGEM",
  type_text: "TEXTO",
  edit_question: "Editar Pergunta",
  clean_filters: "Limpar Filtros",
  static_string: "Strings Estáticas",
  add_static_string: "Criar Nova String Estática",
  string_value: "Valor da String",
  add_feeling: "Criar Novo Sentimento",
  edit_feeling: "Editar Sentimento",
  add_organ: "Criar Novo Orgão",
  edit_organ: "Editar Orgão",
  input_translation: "Por favor, insira todas as traduções.",
  select_answer_type: "Por favor, seleccione o tipo de resposta.",
  set_criteria_as_free: "Definir Critério como gratuito",
  remove_criteria_as_free: "Remover Critério",
  confirm_set_free: "Gostaria de definir este critério como gratuito?",
  confirm_remove_free: "Gostaria de remover este Critério?",
  free_criterias: "Critérios Grátis",
  confirm_set_question_non_required:
    "Gostaria de definir esta pergunta como não-obrigatória ?",
  confirm_set_question_required:
    "Gostaria de definir esta pergunta como obrigatória ?",
  set_non_required: "Definir pergunta como não obrigatória",
  set_required: "Definir pergunta como obrigatória",
  duplicate_questionnaire: "O questionário foi duplicado",
  add: "Adicionar",
  update_questionnaire: "O questionário foi actualizado",
  add_new_app: "Adicionar Nova App",
  translate: "Traduzir",
  delete_general_output:
    "Este output não pode ser eliminado porque está a ser utilizado na APP",
  alternative_report: "Relatório alternativo",
  edit_static_string: "Editar Texto Estático",
  edit_rich_text: "Editar Texto Formatado",
  add_rich_text: "Criar Novo Texto Formatado",
  plain_text: "Texto Simples",
  rich_text: "Texto Formatado",
  create_new_answer_option: "Criar Nova Opção de Resposta",
  edit_answer_option: "Editar Opção de Resposta",
  relevant: "Relevante",
  irrelevant: "Irrelevante",
  enable_criteria: "Activar Critério",
  disable_criteria: "Desactivar Critério",
  set_criteria_as_relevant: "Definir critério como relevante",
  remove_criteria_as_relevant: "Remover critério como relevante",
  confirm_set_relevant: "Gostaria de definir este critério como relevante?",
  confirm_remove_relevant: "Gostaria de remover este critério como relevante?",
  relevant_criteria: "Critérios Relevantes",
  surname: "Apelido",
  role: "Função",
  create_user: "Criar Utilizador",
  week: "Semana",
  sunday: "Domingo",
  monday: "Segunda-Feira",
  tuesday: "Terça-Feira",
  wednesday: "Quarta-Feira",
  thursday: "Quinta-Feira",
  friday: "Sexta-Feira",
  saturday: "Sábado",
  add_week: "Adicionar Semana",
  general_insights: "Percepções Gerais",
  resources_of_focus_insights: "Recursos de insights de foco",
  act_quiz_call_to_action: "Questionário de apelo à ação",
  general_criteria: "Critérios Gerais",
  self_criteria: "Critérios Próprios",
  professional_criteria: "Critérios Profissionais",
  social_criteria: "Critérios Sociais",
  report_and_react: "Reportar e reagir",
  heart_re_question: "Pergunta do Coração",
  highlight_schedule: "Agendamento",
  delete_week: "Apagar semana",
  next_question: "Pergunta Seguinte",
  previous_question: "Pergunta Anterior",
  done: "Submeter Questionário",
  answer_saved: "A resposta foi guardada",
  importar: "Importar",
  exportar: "Exportar",
  import_data: "Importar Dados",
  file_imported: "Ficheiro exportado",
  data_exported: "Dados exportados",
  error_exporting_data: "Erro ao exportar dados",
  EMAIL_ALREADY_EXISTS: "Já existe um utilizador com este email",
  invalid_questionnaire_email:
    "O email não está correto, por favor verifique novamente",
  USER_NOT_FOUND:
    "Utilizador não encontrado. \nPor verifique se o email está correto ou, em alternativa, registe-se.",
  page_not_found: "Página não encontrada",
  error_general: "Ocorreu um erro, por favor tente mais tarde",
  pay: "Pagar",
  payment: "Pagamento",
  payment_succeeded: "Pagamento efetuado com sucesso!",
  payment_unsuccessful:
    "O seu pagamento não foi bem sucedido. Por favor tente novamente.",
  payment_processing: "O seu pagamento está a ser processado.",
  pre_pay_invite: "Convite pré-pago",
  pre_pay_invite_msg: "A quem deseja enviar o convite?",
  error: "Erro",
  export_data: "Exportar Dados",
  invitation: "Convite",
  disable_invitation: "Desactivar Convite",
  enable_invitation: "Activar Convite",
  algorithm_config: "Configuração do Algoritmo",
  user_evolution: "Evolução do Utilizador",
  no_data: "Sem Dados",
  EMAIL_ALREADY_EXISTS: "Email já existe",
  EMAIL_NOT_FOUND:
    "E-mail não encontrado. Por favor, verifique se inseriu corretamente o e-mail",
  answer_type: "Tipo de Resposta",
  import: "Importar",
  export: "Exportar",
  ADMIN: "Administrador",
  CONSULTANT: "Consultor",
  PACIENT: "Paciente",
  add_prescriber: "Adicionar/Modificar Prescritor",
  start_date: "Data Inicial",
  end_date: "Data Final",
  field_required: "Campo Obrigatório",
  report: "Relatório",
  type_to_search: "Escreva para pesquisar",
  summary_of_resources: "Resumo de recursos",
  sensorial_rhythms: "Ritmos Sensoriais",
  select_date: "Selecione uma data",
  confirm_invitation: "Tem a certeza que quer enviar este convite?",
  confirm_answer: "Tem a certeza que quer prosseguir?",
  confirm_prepayment: "Tem a certeza que quer pré-pagar este questionário?",
  free_invite: "Selecione esta opção para tornar o questionário gratuito",
  sending_email_to: "A enviar email para",
  to_fill_the_questionnaire: "para preencher o questionário",
  sending_invitation: "A enviar convite",
  invitation_sent: "Convite enviado",
  an_email_was_sent_to: "Um email foi enviado para",
  payment_page_title:
    "Analisar a sua Atividade Sensorial é essencial para cuidar da sua saúde",
  payment_page_list_title:
    "Ao fazer a análise da sua Atividade Sensorial está a possibilitar:",
  payment_page_list_item_one:
    "um tratamento ajustado à sua atividade fisiológica e biológica;",
  payment_page_list_item_two:
    "uma adaptação informada de recomendações e prescrições;",
  payment_page_list_item_three:
    "uma reactividade organicamente bem sucedida aos cuidados de saúde que necessitar",
  questionnaire_welcome_page_title: "Bem-vindo/a ao seu Sistema Sensorial",
  questionnaire_welcome_page_right_paragraph_one:
    "Tal como uma análise ao sangue, a análise SAYN irá demonstrar com exactidão o que o seu corpo evidentemente informa sobre si, mesmo que você não esteja consciente dessa informação.",
  questionnaire_welcome_page_right_paragraph_two:
    "Analisando os dados e correlações da sua orgânica biológica, será possível ao seu profissional de saúde perceber com clareza qual o cuidado, prescrição ou recomendação mais eficaz para si neste momento.",
  questionnaire_welcome_page_right_paragraph_three:
    "A análise da sua Atividade Sensorial será feita a partir de um questionário, cuja duração de preenchimento é de sensivelmente 15/20 minutos. No entanto, poderá levar o tempo que precisar. Para uma análise bem sucedida, é essencial que convoque este momento só para si.",
  questionnaire_welcome_page_right_list_title:
    "Antes de começar, o que deve saber:",
  questionnaire_welcome_page_right_list_item_one:
    "Responda de forma absolutamente livre e intuitiva, sem hesitar.",
  questionnaire_welcome_page_right_list_item_two:
    "O importante não é o que dizem ou já disseram sobre si, mas sim o que sente sobre si.",
  questionnaire_welcome_page_right_list_item_three:
    "Apele à sua franqueza e espontaneidade para promover uma leitura em perfeita sincronia com a sua orgânica. Não há respostas certas ou erradas.",
  questionnaire_welcome_page_right_list_item_four:
    "O questionário terá de ser preenchido na sua totalidade. Não poderá preencher uma parte agora e outra depois.",
  questionnaire_welcome_page_right_list_item_five:
    "Se hesitar numa resposta, não se preocupe. Recomenda-se que simplesmente passe à questão seguinte.",
  questionnaire_welcome_page_right_footnote:
    "Caso não queira avançar agora: Volte a clicar no link que recebeu por email para regressar mais tarde. Tem 30 dias para preencher o questionário, a contar da data de subscrição.",
  questionnaire_welcome_start_button_text: "Começar análise",
  questionnaire_completed_page_title:
    "A sua análise estará pronta em {{hours}} horas",
  questionnaire_completed_page_left_text:
    "E será enviada para o seu email, para que a partilhe com o seu profissional de saúde. Para mais informações sobre a primeira análise que descodifica o Sistema Sensorial em tempo real, consulte saynlab.com",
  questionnaire_completed_page_right_text:
    "Recomende um cuidado em perfeito alinhamento com a verdadeira natureza de cada indivíduo, começando por quem mais gosta. Qualquer amigo ou familiar pode beneficiar desta análise para cuidar da sua saúde de forma personalizada. Recomendar é cuidar.",
  questionnaire_completed_page_submitted_text:
    "Os seus dados foram submetidos com sucesso."
};

addLocaleData([...pt]);

export default messages;
